<template lang="pug">
  section.l-section
    .l-section-h
      b-card.card-transparent
        .pb-5.text-center
          .wc-i.wc-symbol.rounded(style='width: 80px; height: 80px')
          .h4.m-0.mt-2.text-uppercase.text-white
            | {{ $wc.conf.base.name }}

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        b-form.form-wc.form-password-new.mt-4(
          @submit.stop.prevent='formSubmit'
          @input.stop.prevent='formChange'
          @reset.stop.prevent='formReset')

          transition(name='fade')
            div(v-if='formShow')
              b-form-group#form-account-email.form-input-icon(
                :label="$t('session.placeholders.email')"
                label-for='account-email'
                label-sr-only)
                i.input-icon.text-primary.fal.fa-fw.fa-envelope
                b-form-input#account-email.is-required(
                  name='email'
                  type='email'
                  autocomplete='email'
                  :placeholder="$t('session.placeholders.email')"
                  :state='formValidationState($v.form.data.attributes.email)'
                  @focus.native='formChange'
                  v-model='$v.form.data.attributes.email.$model'
                  aria-describedby='account-email-feedback'
                  trim)
                wc-forms-if#account-email-feedback(
                  extra-class='ml-feedback'
                  :attribute='$v.form.data.attributes.email'
                  :remote="formRemoteInvalidFeedback('email')"
                  :validators='{ required: {}, regExp: {}, maxLength: {} }')

              b-button.mt-4(
                type='submit'
                variant='secondary'
                :disabled="$v.form.data.$invalid"
                block)
                i.fal.fa-fw.fa-key
                |  {{ $t('session.reset_password') }}

        .mt-5.help.text-center.small
          b-link(:to="{ name: 'login' }")
            span.text-body
              i.fal.fa-fw.fa-question-circle
              |  {{ $t('session.help.password') }}
            span.text-capitalize
              |  {{ $t('session.sign_in') }}

        loading(backgroundRadius='2rem' :active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import { Form } from '@common/form'
import apiPassword from '@services/api/account/password'
import { regExp } from '@common/form/validations'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'account-password-new',
  mixins: [Form],
  computed: {
    apiParams() {
      return {
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            email: {
              required,
              regExp: regExp(this.$wc.conf.regex.email_browser),
              maxLength: maxLength(this.$wc.conf.limits.max_email),
              remote: () => this.formRemoteValid('email'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      skipFormFill: true,
      apiBase: apiPassword,
      apiModel: 'account',
      formBootstrapDefaults: {
        create: {
          data: {
            attributes: {
              email: '',
            },
          },
        },
      },
    }
  },
}
</script>
